import React, { useContext, useState, useEffect, useRef } from "react";

// Material
import { IconButton } from '@mui/material';

import { TbHeadphones } from "react-icons/tb";
import { TbHeadphonesOff } from "react-icons/tb";

import { CallContext } from "../../contexts/CallContext";



/**
 * Call Button Component
 * @returns {JSX.Element} - Call Button Component
 */


export const CallBtn = ({ iconBtnSx, onCallIconBtnSx }) => {
	const { isOnCall, setIsOnCall, setSpeechToText, chatbotMsg } = useContext(CallContext);

	const recognizerRef = useRef(null);
    const utteranceRef = useRef(null);
	useEffect(() => {
		let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || null;

		if (!('SpeechSynthesisUtterance' in window) || !SpeechRecognition) {
			document.querySelector('.js-api-support').removeAttribute('hidden');
		} else {
			utteranceRef.current = new SpeechSynthesisUtterance();
			var selectedVoice = speechSynthesis
			.getVoices()
			.filter(function(voice) {
				console.log(voice.voiceURI)
				return voice.voiceURI === "Microsoft Pablo - Spanish (Spain)";
			})
			.pop();
			utteranceRef.current.voice = selectedVoice;
			utteranceRef.current.rate = 0.8;
			utteranceRef.current.pitch = 1;

			recognizerRef.current = new SpeechRecognition();
			recognizerRef.current.lang = 'es-ES';

			recognizerRef.current.addEventListener('result', function(event) {
				let transcription = '';
				for (var i = event.resultIndex; i < event.results.length; i++) {
					if (event.results[i].isFinal) {
						transcription = event.results[i][0].transcript;
					} else {
						transcription += event.results[i][0].transcript;
					}
				}
				console.log(transcription);
				setSpeechToText(transcription);
			});

			recognizerRef.current.addEventListener('error', function(event) {
				console.log('error: ' + event);
			});

			recognizerRef.current.addEventListener('start', function() {
				console.log('Recognition start');
			});

			recognizerRef.current.addEventListener('end', function() {
				console.log('Recognition end');
			});

			speechSynthesis.addEventListener('voiceschanged', function onVoiceChanged() {
				speechSynthesis.removeEventListener('voiceschanged', onVoiceChanged);
			});
		}

		return () => {
			if (recognizerRef.current) {
				recognizerRef.current.stop();
			}
			if (utteranceRef.current) {
				speechSynthesis.cancel();
			}
		};
	}, []);
	
	useEffect(() => {
		if (!recognizerRef.current || !utteranceRef.current) return;
		
		if (!isOnCall) {
			recognizerRef.current.stop();
			speechSynthesis.cancel();
			setSpeechToText('');
		} else {
			utteranceRef.current.addEventListener('end', function() {
				if (isOnCall) {
					recognizerRef.current.start();
				}
			});
		}
	}, [isOnCall, chatbotMsg]);

	useEffect(() => {
		if (chatbotMsg && isOnCall && utteranceRef.current) {
			utteranceRef.current.text = chatbotMsg;
			speechSynthesis.speak(utteranceRef.current);
		}
	}, [chatbotMsg, isOnCall]);

	const handleCall = () => {
		setIsOnCall(!isOnCall);
	};

	return (
		<IconButton
			onClick={handleCall}
			sx={isOnCall ? onCallIconBtnSx : iconBtnSx}
			disableRipple
		>
			{isOnCall ? <TbHeadphonesOff /> : < TbHeadphones/>} 
		</IconButton>
	);
};
