import React, { useContext, useEffect, useState } from "react";
import { getSessionMessages } from "../utils/localTracker";

// Styles
import "../assets/css/chatbot-zalcu.css";

// Hooks
import { useChat } from "../hooks/useChat";
import { VisibilityToggleBtn } from "./buttons/VisibilityToggleBtn";

// Components
import ChatContainer from "./chat/ChatContainer";
import { ChatUIContext } from "./ChatbotUI";

// Default Chatbot messages context
export const ChatContext = React.createContext({
    chatMessages: [],
    emptyChat: () => {},
    handleUserMessage: () => {},
});

/**
 * Chatbot Component - Provides the context for the chatbot messages
 * @param {object} props - Component props
 * @param {string} props.title - Title of the chatbot
 * @returns {JSX.Element} - Chatbot Component
 */
export const Chatbot = ({ title }) => {
    const { chatIsVisible, toggleVisibility } = useContext(ChatUIContext);
    const { chatMessages, handleUserMessage, emptyChat } = useChat();
    const contextValue = { chatMessages, handleUserMessage, emptyChat };
    const [isFirstMessage, setIsFirstMessage] = useState(true);

    // Send first message
    useEffect(() => {
        const sendFirstMessage = () => {
            if (getSessionMessages().length === 0) {
                handleUserMessage({ text: "Hola" }, isFirstMessage);
                setIsFirstMessage(false);
            }
        }

        sendFirstMessage();
    }, []);

    return (
        <ChatContext.Provider value={contextValue}>

            {/* Floating button */}
            <VisibilityToggleBtn
                chatIsVisible={chatIsVisible}
                toggleVisibility={toggleVisibility}
            />

            {/* Main container */}
            <ChatContainer />

        </ChatContext.Provider>
    );
};
