import { useState, useContext } from "react";

// Context
import { ChatContext } from "../Chatbot.js";

// Components
import { MessageBtn } from "../buttons/MessageBtn.js";

// Material
import { Box } from "@mui/material";

// Constants
const MSG_BTN_DELAY = 750;

/**
 * List component of buttons to be displayed in as options to the user.
 * @param {object} props - Props object
 * @param {array} props.messageButtons - Array of buttons to be displayed
 * @returns {JSX.Element} - List of buttons
 */
const isFullScreen = process.env.REACT_APP_CHATBOT_FULL_SCREEN === 'true';

export const ButtonsMessage = ({ messageButtons, isMaximized }) => {
    const [isVisible, setIsVisible] = useState(true);
    const { handleUserMessage } = useContext(ChatContext);

    // const variants = {
    //     hidden: {
    //         height: 0,
    //         transition: { duration: 0.5 },
    //     },
    //     visible: {}, // No animation
    // };

    // Send message to server and hide the buttons after the user clicks on one of them
    const handleClick = (text, payload) => {
        handleUserMessage({ text, payload })
        setIsVisible(false);
    };



    return (
        <Box className="button-messages-container">
            {isVisible && messageButtons.map((button, index) => (
                <MessageBtn
                    key={index}
                    text={button.title}
                    payload={button.payload}
                    delay={index * MSG_BTN_DELAY}
                    onMsgBtnClick={handleClick}
                />
            ))}
        </Box>        
    );
};
