import { callOpenAiAPI } from "../api/openaiAPI";
import { callRasaAPI } from "../api/rasaAPI";
import { getSenderUUID } from "./localStorage";

/**
 * Function to send a message to the chatbot server and return the bot messages
 * @param {string} senderUUID - UUID of the user sending the message.
 * @param {string} payload - Payload of the message sent to the server. Defaults to text.
 * @returns {Array} - Array of bot messages to update chat state.
 */
export async function sendUserMessage( payload ){ 

    // Get sender UUID
    const senderUUID = getSenderUUID();


    // Get response from rasa virtual assistant or OpenAI
    if (localStorage.getItem("openai") === "false") {
        return await callRasaAPI({senderUUID, payload});
    } else {
        return await callOpenAiAPI({senderUUID, payload});
    }
    
}