import React from "react";

import { useContext } from "react";
import { ChatContext } from "../Chatbot";
import { ChatUIContext } from "../ChatbotUI";
import { DelaySwitcher } from "./DelaySwitcher";

// Material
import { Divider, IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FaTrash, FaPowerOff } from 'react-icons/fa';
import { OpenAIModelSwitcher } from "./OpenAIToogleBtn";

/**
 * Dropdown Menu Content Component
 * @param {boolean} is_open - Dropdown menu visibility
 * @returns {JSX.Element} - Dropdown menu content
 */
export const DropdownMenuContent = ({ toggleDropdownVisibility, iconBtnSx }) => {
    const { emptyChat, handleUserMessage } = useContext(ChatContext);
    const { toggleVisibility } = useContext(ChatUIContext);    
   
    const theme = useTheme();
    const dropdownOptionIconBtnSx = {
        color: theme.palette.dropdownMenu.color,
        "&:hover": {
            color: theme.palette.dropdownMenu.colorHover,
            backgroundColor: 'inherit'
        },        
    }

    function handleCleanHistory() {
        emptyChat();
        toggleDropdownVisibility();
    }

    function handleEndConversationButton() {
        handleUserMessage({ text: "Adiós" });
        toggleDropdownVisibility();

        setTimeout(() => {
            toggleVisibility();
        }, 2000);
    }

    return (
        <Box>
            {/* Clean history button */}
            <IconButton
                className="dropdown-option"
                onClick={handleCleanHistory}
                sx={dropdownOptionIconBtnSx}
                disableRipple
            >
                <FaTrash />
                Limpiar Historial
            </IconButton>

           <Divider sx={{borderBottom: `1px solid ${theme.palette.border.main}`}} />

            {/* End conversation button */}
            <IconButton
                className="dropdown-option"
                onClick={handleEndConversationButton}
                sx={dropdownOptionIconBtnSx}
                disableRipple
            >
                <FaPowerOff />
                Finalizar conversación
            </IconButton>

            <Divider sx={{borderBottom: `1px solid ${theme.palette.border.main}`}} />

            {/* Switch delay mode */}
            <DelaySwitcher iconBtnSx={dropdownOptionIconBtnSx}/>

            <Divider sx={{borderBottom: `1px solid ${theme.palette.border.main}`}} />
    
            {/* Switch OpenAI model */}
            <OpenAIModelSwitcher iconBtnSx={dropdownOptionIconBtnSx}/>
        </Box>
    );
};
