/**
 * Get the current time.
 * @returns {String} - Current time in the format HH:MM:SS.
 */
export function getMessageTime() {
    const date = new Date();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}

const CHAR_DELAY = process.env.REACT_APP_MESSAGE_CHAR_DELAY || 45;

/**
 * Calculate the delay time for the message based on the number of characters.
 * @param {String} message - Message to be sent.
 * @param {Number} accumulatedDelay - Previous accumulated delay time.
 * @returns {Object} - Delay time as a formula of the number of characters in the message and the accumulated delay time.
 */
export function getMessageDelay(message) {
    const length = message?.length || 0;
    return length * CHAR_DELAY;
}