import React from "react";
import { useEffect, useRef } from "react";

// Material
import { Box } from "@mui/material";

/**
 * Component that scrolls to the end of the chat when components (buttons) are displayed.
 * @param {object} props - Component props
 * @param {JSX.Element} props.children - Children components
 * @returns {JSX.Element} - Scroll component
 */
export const ScrollComponent = ({ children }) => {
    const endRef = useRef(null);

    useEffect(() => {
        endRef.current?.scrollIntoView();
    }, []);

    return (
        <Box ref={endRef}>
            {children}
        </Box>
    );
};
