import {React} from "react";

// Messages Components
import { ButtonsMessage } from "./ButtonsMessage";
import { ImageMessage } from "./ImageMessage";
import { TextMessage } from "./TextMessage";
import { UrlMessage } from "./UrlMessage";

// Material
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";

/**
 * Chat Message Component
 * @param {Object} props - Component props
 * @param {Object} props.message - Message object
 * @param {String} props.message.sender - Sender of the message
 * @param {Boolean} props.message.displayAvatar - Boolean to display the avatar
 * @param {String} props.message.timestamp - Timestamp of the message
 * @param {String} props.message.text - Text content of the message
 * @param {String} props.message.image - Image content of the message
 * @param {String} props.message.buttons - Buttons content of the message
 * @param {String} props.message.url - URL content of the message
 * @param {Function} props.setChat - Function to update the chat messages
 * @returns 
 */

const isFullScreen = process.env.REACT_APP_CHATBOT_FULL_SCREEN === 'true';

export const ChatMessage = ({ message, isMaximized }) => {
    const theme = useTheme();
    return (
        <Box 
            className={`body__chat-message body__${message.sender}-message`}
            sx={{
                [theme.breakpoints.up('xs')]: {
                    marginLeft: isFullScreen && isMaximized ? '1rem' : undefined,
                    marginRight: isFullScreen && isMaximized ? '1rem' : undefined,
                },
                [theme.breakpoints.up('sm')]: {
                    marginLeft: isFullScreen && isMaximized ? '4rem' : undefined,
                    marginRight: isFullScreen && isMaximized ? '4rem' : undefined,  
                },
                [theme.breakpoints.up('md')]: {
                    marginLeft: isFullScreen && isMaximized ? '15rem' : undefined,
                    marginRight: isFullScreen && isMaximized ? '15rem' : undefined,
                },
                [theme.breakpoints.up('xl')]: {
                    marginLeft: isFullScreen && isMaximized ? '35rem' : undefined,
                    marginRight: isFullScreen && isMaximized ? '35rem' : undefined,
                }
            }}
        >

            {/* Message Text Content */}         
            {message.text && (
                <TextMessage
                    sender={message.sender}
                    text={message.text}
                    timestamp={message.timestamp}
                    isMaximized={isMaximized}
                />
            )}

            {/* Message Image Content */}
            { message.image && (
                <ImageMessage src={message.image} />
            )}

            {/* Message Buttons Content */}
            { message.buttons && (
                <ButtonsMessage messageButtons={message.buttons} isMaximized={isMaximized}/>
            )}

            {/* Message URL Content */}
            { message.url && (
                <UrlMessage
                    text={message.url.text}
                    href={message.url.href}
                    timestamp={message.timestamp}
                    isMaximized={isMaximized}
                />
            )}
        </Box>
    );
};
