import { v4 } from 'uuid';

/**
 * Get and/or create last session timestamp in local storage.
 * @returns {String} - Timestamp.
 */
export function getSenderTimestamp () {
    const timestamp = localStorage.getItem('lastSession')

    // Timestamp exists
    if (timestamp) {
        return timestamp
    }

    localStorage.setItem('lastSession', new Date())
    return localStorage.getItem('lastSession')
}

/**
 * Get and/or create user uuid in local storage.
 * @returns {String} - User uuid.
 */
export function getSenderUUID() {
    const uuid = localStorage.getItem('uuid')

    //Get or create user uuid
    if(!uuid) {
        localStorage.setItem('uuid', v4())         
    } 

    // Renew timestamp token each time the user uuid is requested.
    localStorage.setItem('lastSession', new Date())

    return localStorage.getItem('uuid')
}

/**
 * Get user chatbot options from local storage.
 * @returns {Object} - User options.
 */
export function getUserOptions() {
    const userOptions = localStorage.getItem('userOptions')

    //Get or create user options
    if(!userOptions) {
        localStorage.setItem('userOptions', JSON.stringify({}))         
    } 

    return JSON.parse(localStorage.getItem('userOptions'))
}

/**
 * Set user chatbot options in local storage.
 * @param {String} key - Option key.
 * @param {String} value - Option value.
 */
export async function setUserOptions(key, value){
    const userOptions = getUserOptions()

    // Set option - Bracket notation is used to set the key dynamically.
    userOptions[key] = value

    localStorage.setItem('userOptions', JSON.stringify(userOptions))
}

/**
 * Check lastSession timestamp in local storage for message retrieval.
 * @returns {Boolean} - True if first session of the user or active session has expired.
*/ 
export function checkSessionExpiration() { 

  // Gets the value of 'lastSession' from the localStorage
  const lastSessionString = localStorage.getItem('lastSession');

  // If 'lastSession is not set. First session.
  if (!lastSessionString) {
    return true;
  }

  // Convert 'lastSession' to a Date object
  const lastSession = new Date(lastSessionString);

  // Verify that 'lastSession' is a valid date
  if (isNaN(lastSession)) {
    console.error('El valor de lastSession no es una fecha válida.');
    return;
  }

  // Get the current time
  const currentTime = new Date();

  // Calculate the time difference between the current time and the last session
  const timeDifference = currentTime - lastSession;

  // Convert the time difference to hours
  const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);

  // if the time difference is greater than 1 hour, return true, otherwise return false
  return timeDifferenceInHours > 1;
}

/**
 * Get selected model from local storage.
 * @returns {String} - Selected model.
 */
export function getSelectedModelInLocal() {
    return localStorage.getItem('model')
}

/**
 * Set selected model in local storage.
 * @param {String} model - Selected model
 */

export function setSelectedModelInLocal(model) {
    localStorage.setItem('model', model);
}

