import React from 'react'

import { motion } from "framer-motion"

// Material
import { Box } from "@mui/material";

/**
 * Animated component for bottom up animation
 * @param {object} props - Props object
 * @param {JSX.Element} props.children - Children to be animated
 * @param {string} props.className - Class name to be applied to the component
 * @returns {JSX.Element} - Animated component
 */
export const Animated = ({children, className}) => {

  return (
    <Box
      component={motion.div}
      animate={{
        y: [50, 0],
        opacity: [0, 1]
      }}
      className={`${className ?? ""}`}
    >
      { children }
    </Box>
  )
}
