import { getSenderUUID } from "./localStorage";
import { getMessageTime } from "./time";

/**
 * Create bot messages object to update chat state from rasa server response.
 * @param {Array} botMessages - Array of bot messages from rasa server response.
 * @returns {Array} - Array of bot messages to update chat state.
 */
export function createBotMessages(botMessages) {
    return botMessages.map((botMessage, index) => ({
        sender: "bot",
        timestamp: getMessageTime(),
        displayAvatar: index === 0,
        recipientUUID: botMessage?.recipient_id,
        text: botMessage?.text,
        image: botMessage?.image,
        buttons: botMessage?.buttons,
        url: botMessage?.custom?.url,
    }))
}

/**
 * Create error message object to update chat state.
 * @param {String} text - Text of the error message displayed.
 * @returns {Array} - Error message object to update chat state.
 */
export function createErrorMessage(text){
    return [{
        sender: "bot",
        timestamp: getMessageTime(),
        displayAvatar: true, 
        recipientUUID: 'bot',
        text: text,
    }]
}

/**
 * Function to create user message object to update chat state.
 * @param {string} text - Text of the message displayed.
 * @param {string} payload - Payload of the message sent to the server. Defaults to text.
 * @returns {object} - User message object to update chat state.
*/
export function createUserMessage(text){
    return {
        sender: "user",
        timestamp: getMessageTime(),
        displayAvatar: false, 
        recipientUUID: 'bot',
        senderUUID: getSenderUUID(), 
        text: text,
    }
}