import React from "react";
import { Animated } from "../utility/AnimatedComponent";

// Material
import { Box, Link } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { CustomTooltip } from "../utility/CustomTooltip";

/**
 * Url Message Component
 * @param {Object} props - Component props
 * @param {String} props.text - Text content of the link
 * @param {String} props.href - href content of the link  
 * @returns {JSX.Element} - Url Message Component
 */

const isFullScreen = process.env.REACT_APP_CHATBOT_FULL_SCREEN === 'true';

export const UrlMessage = ({ text, href, timestamp, isMaximized }) => {
    const theme = useTheme();
    const linkSx = {
        color: theme.palette.link.main,
        textDecoration: 'none',

        '&:hover': {
            color: theme.palette.link.hover
        }
    }
    return (
        <Animated>
            {/* Time */}
            <CustomTooltip 
                title={timestamp} 
                placement="right"
                PopperProps={{disablePortal: true}}
                sx={{zIndex: 1}}
            >
                <Box 
                    className={`chat-bubble`}
                    sx={{
                        boxShadow: isFullScreen && isMaximized ? undefined : theme.palette.chatMsg.shadow,
                        background: isFullScreen && isMaximized ? theme.palette.chatMsgFullScreen.background : theme.palette.chatMsg.background
                    }}
                >
                    <Link
                        href={href}
                        sx={linkSx}
                    >
                        {text}
                    </Link>
                </Box>
            </CustomTooltip>
        </Animated>
    );
};
