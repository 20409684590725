import React from "react";
import { ChatInput } from "./ChatInput";

// Material
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Link } from "@mui/material";

export const ChatFooterFullScreen = () => {
    const theme = useTheme();
    return (
        <Box 
            className="chat-footer"
            sx={{
                backgroundColor: 'red',
                color: theme.palette.chatFooterFullScreen.color,
                boxShadow: theme.palette.chatFooterFullScreen.shadow,
            }}
        >
            <ChatInput/>
        </Box>

    );
};
