import React from "react";
import { ChatInput } from "./ChatInput";

// Material
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Link } from "@mui/material";

export const ChatFooter = () => {
    const theme = useTheme();
    const linkSx = {
        color: theme.palette.link.main,
        textDecoration: 'none',

        '&:hover': {
            color: theme.palette.link.hover
        }
    }

    return (
        <Box 
            className="chat-footer"
            sx={{
                backgroundColor: theme.palette.chatFooter.background,
                color: theme.palette.chatFooter.color,
                boxShadow: theme.palette.chatFooter.shadow,
            }}
        >
                <ChatInput/>
                <Box className="input-banner">
                    <Typography variant="body6">
                        Powered by <Link href="https://zalcu.com/" sx={linkSx} > Zalcu </Link>
                    </Typography>
                </Box>
        </Box>

    );
};
