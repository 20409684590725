import React from "react";

// Material
import { IconButton } from '@mui/material';
import { RxDash, RxStop} from 'react-icons/rx';
import { palette } from "@mui/system";

/**
 * Minimize Chat Button Component
 * @returns {JSX.Element} - Minimize Chat Button Component
 */
export const MinimizeBtn = ({ iconBtnSx, handleToggleMaximize, isMaximized }) => {
    return (
        <IconButton
            onClick={ handleToggleMaximize}
            sx={iconBtnSx}
            disableRipple
        >
            {isMaximized ? < RxDash/> : <RxStop size="1.4rem"/>} 
        </IconButton>
    );
};
