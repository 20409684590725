import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses} from '@mui/material/Tooltip';


export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({

    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'inherit',
      color: theme.palette.black.main,
      fontSize: 11.5,
    },
  }));
  