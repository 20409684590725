import { useState } from "react";

// Framer Motion
import { motion } from "framer-motion";

// Dropdown components
import { DropdownMenuBtn } from "../buttons/DropdownMenuBtn";
import { DropdownMenuContent } from "../buttons/DropdownMenuContent";

// Material
import { Box } from "@mui/material";

/**
 * Dropdown Menu Button Component
 * @returns {JSX.Element} - Dropdown Menu Button Component
 */

export const DropdownMenu = ( {iconBtnSx} ) => {
    const [menuDropdownIsOpen, setMenuDropdownIsOpen] = useState(false);

    const toggleMenuDropdownVisibility = () => {
        setMenuDropdownIsOpen((menuDropdownIsOpen) => !menuDropdownIsOpen);
    };

    // Animation variants
    const variants = {
        open: { y: 0, height: "auto" },
        closed: { y: "-100%", height: "0" },
    };

    return (
        <Box sx={{ backgroundColor: 'inherit' }}>
            {/* Dropdown toggle button*/}
            <DropdownMenuBtn
                toggleDropdownVisibility={toggleMenuDropdownVisibility}
                iconBtnSx={iconBtnSx}
            />

            {/* Animated dropwdown content */}
            <Box
                component={motion.div}
                animate={menuDropdownIsOpen ? "open" : "closed"}
                variants={variants}
                className="header__dropdown-content"
                sx={{
                    backgroundColor: 'inherit',
                    borderRadius: '0rem 0rem 1rem 1rem',
                    right: '10%',
                }}
            >
                <DropdownMenuContent
                     toggleDropdownVisibility={toggleMenuDropdownVisibility}
                     iconBtnSx={iconBtnSx}
                 />
            </Box>
        </Box>
    );
};
