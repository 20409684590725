import React, { useContext }  from "react";
import { Hidden } from '@mui/material';

// Context
import { ChatUIContext } from "../ChatbotUI";

// Components
import { DropdownMenu } from "./DropdownMenu";
import { CallBtn } from "../buttons/CallBtn";
import { MinimizeBtn } from "../buttons/MinimizeBtn";
import { CloseBtn } from "../buttons/CloseBtn";

// Material
import { useTheme } from '@mui/material/styles';
import { Box, Typography, ButtonGroup } from "@mui/material";

/**
 * Chat Header Component
 * @returns {JSX.Element} - Chat Header Component
 */
export const ChatHeader = ({ handleToggleMaximize, isMaximized }) => {
    const {title} = useContext(ChatUIContext);
    const theme = useTheme();

    const headerIconBtnSx = {
        color: theme.palette.headerIconBtn.color,
        "&:hover": {
            color: theme.palette.headerIconBtn.hover_color,
            backgroundColor: theme.palette.headerIconBtn.hover_background
        },
    };

	const headerCallIconBtnSx = {
        color: theme.palette.onCallheaderIconBtn.color,
        "&:hover": {
            color: theme.palette.onCallheaderIconBtn.hover_color,
            backgroundColor: theme.palette.onCallheaderIconBtn.hover_background
        },
    };

    return (
        <Box 
            className="chat-header"
            sx={{
                backgroundColor: theme.palette.chatHeader.background,
                boxShadow: theme.palette.chatHeader.shadow,
            }}
        >
            <Typography variant='h6'
                sx={{
                    fontColor: theme.palette.black.main,
                    fontWeight: 900,
                }}
            >
                {title}
            </Typography>

            <ButtonGroup variant="outlined" 
                className="header__buttons-container"
                sx={{backgroundColor: 'inherit'}}
            >
                {/* Dropdown Menu */}
                <DropdownMenu iconBtnSx={headerIconBtnSx}/>

				{/* Call Button */}
				<CallBtn  iconBtnSx={headerIconBtnSx} onCallIconBtnSx={headerCallIconBtnSx}/>

                {/* Minimize/Maximize Button */}
                <Hidden smDown>
                    <MinimizeBtn  iconBtnSx={headerIconBtnSx} handleToggleMaximize={handleToggleMaximize} isMaximized={isMaximized}/>
                </Hidden>
                
                {/* Close Button */}
                <CloseBtn iconBtnSx={headerIconBtnSx}/>
            </ButtonGroup>
        </Box>
    );
};
