import { useEffect } from "react";
import { useState, useCallback  } from "react";
import { getUserOptions, setUserOptions } from "../utils/localStorage";

/**
 * @typedef {Object} ChatUI
 * @property {Boolean} chatIsVisible - Chat UI visibility state
 * @property {() => void} toggleVisibility - Toggle function for the chat UI visibility state
 * @property {Boolean} isThinking - Chat UI message display state
 * @property {() => void} toggleThinking - Toggle function for the chat UI message display state
 * @property {Array} messageDisplayTimers - Array of timers to display messages
 * @property {() => void} setMessageDisplayTimers - Set function for the message display timers
 * @property {Boolean} chatHasDelayMessages - Clear function for the message display timers
 * @property {() => void} toggleMessageDelay - Clear function for the message display timers
 */

/**
 * Custom hook to manage the chatbot UI state.
 * @returns {ChatUI} - Chat UI state and toggle functions
 */
export const useChatUI = () => {

  const [chatIsVisible, setChatIsVisible] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [messageDisplayTimers, setMessageDisplayTimers] = useState([]);
  const [chatHasDelayMessages, setChatHasDelayMessages] = useState(true);

  const toggleVisibility = () => {
    setChatIsVisible((state) => !state)
    setUserOptions('chatIsVisible', !chatIsVisible)
  };
  const toggleMessageDelay =  () => {
    setUserOptions('chatHasDelayMessages', !chatHasDelayMessages)
    setChatHasDelayMessages((state) => !state)
  };
  
  // Avoid unnecessary re-renders when the state is changed
  const toggleThinking =  useCallback(() => {setIsThinking((state) => !state)}, []);
  

  // Restore user options for the chatbot UI when the component is mounted for the first time.
  useEffect(() => {
    const userOptions = getUserOptions()
    setChatIsVisible(userOptions?.chatIsVisible ?? true);
    setChatHasDelayMessages(userOptions?.chatHasDelayMessages ?? true);
  },[])

  return {
    chatIsVisible,
    toggleVisibility,
    isThinking,
    toggleThinking,
    messageDisplayTimers,
    setMessageDisplayTimers,
    chatHasDelayMessages,
    toggleMessageDelay,
  }
}
