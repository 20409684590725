import React, { createContext, useEffect, useState } from 'react';

const WindowContext = createContext();

const WindowProvider = ({ children }) => {
    const [windowPercentage, setWindowPercentage] = useState(0);


  useEffect(() => {
    const handleResize = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
  
        const percentage = (windowWidth * windowHeight) / (window.screen.width * window.screen.height) * 100;
  
        setWindowPercentage(percentage);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Call the handleResize function initially
      handleResize();
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <WindowContext.Provider value={windowPercentage}>
      {children}
    </WindowContext.Provider>
  );
};

export { WindowContext, WindowProvider };