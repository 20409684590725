import React from "react";
import { useContext } from "react";

// Context
import { ChatUIContext } from "../ChatbotUI";

// Material
import { IconButton } from '@mui/material';
import { IoIosClose } from 'react-icons/io';

/**
 * Minimize Chat Button Component
 * @returns {JSX.Element} - Minimize Chat Button Component
 */
export const CloseBtn = ({ iconBtnSx }) => {
    
    const { toggleVisibility } = useContext(ChatUIContext);

    return (
        <IconButton
            onClick={toggleVisibility}
            sx={iconBtnSx}
            disableRipple
        >
            <IoIosClose/>
       
        </IconButton>
    );
};
