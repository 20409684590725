import { createErrorMessage } from "../utils/messageBuilders";

export async function callRasaAPI({ senderUUID, payload } ) {

    try{
        // Send user msg to rasa server
        const response = await fetch(
            `${process.env.REACT_APP_CHATBOT_API_URL}`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    charset: "UTF-8",
                    Origin: `${process.env.REACT_APP_ORIGIN}`,
                },
                body: JSON.stringify({ sender: senderUUID, message: payload }),
            }
        );
    
        // Get response from rasa virtual assistant
        const botMessages = await response.json();
    
        return botMessages;

    } catch (error) {
        return createErrorMessage("Estoy teniendo problemas para conectarme con el servidor 😢. Por favor, inténtalo de nuevo más tarde.")
    }
}


