/** Función para obtener respuesta de la API de openAI */

import { getSessionMessages } from "../utils/localTracker";

const openAIAPIContext = [
    {
        role: "system",
        content: "Háblame en castellano",
    },
    {
        role: "system",
        content: "Eres un asistente de Zalcu Technologies",
    },
    {
        role: "system",
        content: "Zalcu Technologies ofrece soluciones digitales personalizadas para mejorar la productividad y eficiencia empresarial utilizando modelos de ia",
    }
];

/**
 * Send a request to OpenAI to get an inference response
 * @param {*} message 
 */
const sendOpenAIInferenceRequest = async (message) => {

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "OpenAI-Organization": process.env.REACT_APP_OPENAI_ORGANIZATION,
            "OpenAI-Project": process.env.REACT_APP_OPENAI_PROJECT,
        },
        body: JSON.stringify({
            model: process.env.REACT_APP_OPENAI_MODEL,
            messages: [
                ...openAIAPIContext,
                {
                    role: "user",
                    content: message,
                }
            ]
        }),
    };

    const response = await fetch(
        `https://api.openai.com/v1/chat/completions`,
        requestOptions
    );

    const data = await response.json();

    return data.choices[0].message.content;
};



/**
 * 
 * @param {*} SenderUUID - ID del usuario
 * @param {*} payload - Mensaje del usuario
 * @param {*} payload.message - Mensaje del usuario
 *  
 */
async function callOpenAiAPI({ senderUUID, payload }){

    const completion = await sendOpenAIInferenceRequest(payload);

    return [{
        recipient_id: senderUUID,
        text:completion,
    }];
}

export { 
    callOpenAiAPI 
};