import { React, useContext, useEffect } from "react";

// Components
import { Animated } from "../utility/AnimatedComponent";
import { CustomTooltip } from "../utility/CustomTooltip";

// Material
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import { CallContext } from "../../contexts/CallContext";

/**
 * Text Message Component
 * @param {object} props - Component props
 * @param {string} props.text - Text content of the message
 * @param {string} props.senderCls - Sender class of the message
 * @returns {JSX.Element} - Text Message Component
 */

const isFullScreen = process.env.REACT_APP_CHATBOT_FULL_SCREEN === 'true';

export const TextMessage = ({ sender, text, timestamp, isMaximized }) => {
    const theme = useTheme();
    const { setChatbotMsg } = useContext(CallContext);
    useEffect(() => {
        if (sender === "bot") {
            setChatbotMsg(text);
        }
    }, [sender, text, setChatbotMsg]);
    return (
        <Animated>
                {/* Time */}
                <CustomTooltip 
                    title={timestamp} 
                    placement={sender === "bot" ? "right" : "left"}
                    // placement="top"
                    PopperProps={{disablePortal: true}}
                    sx={{zIndex: 1}}
                >
                    <Box
                        className="chat-bubble"
                        sx={{
                            boxShadow: isFullScreen && isMaximized ? undefined : theme.palette.chatMsg.shadow,
                            color: sender === "bot" ? theme.palette.chatMsg.botText : theme.palette.chatMsg.userText,
                            background:  isFullScreen && isMaximized ? theme.palette.chatMsgFullScreen.background : theme.palette.chatMsg.background
                        }}
                    >
                        {text}
                    </Box>
                </CustomTooltip>
        </Animated>
    );
};
