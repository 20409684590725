import React, { createContext, useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';


const CallContext = createContext({});

/**
 * CallProvider component to provide Call functions and state to children
 */
const CallProvider = ({ children }) => {

    const [isOnCall, setIsOnCall] = useState(false);
    const [speechToText, setSpeechToText] = useState("");
    const [ chatbotMsg, setChatbotMsg ] = useState("");

    return (
        <CallContext.Provider value={{isOnCall, setIsOnCall, speechToText, setSpeechToText, chatbotMsg, setChatbotMsg}}>
            {children}
        </CallContext.Provider>
    )
}

CallProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export {
    CallProvider,
    CallContext
}