import React, { useState, useContext } from 'react';

import { IconButton } from '@mui/material';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';

import { ChatContext } from "../Chatbot";

/**
 * Component to toggle the OpenAI model response
 * @param {object} iconBtnSx - Icon button styles
 */
const OpenAIModelSwitcher = ({ iconBtnSx }) => {

    const { handleUserMessage } = useContext(ChatContext);

    const [useOpenAiModel, setUseOpenAiModel] = useState(
        localStorage.getItem("openai") === 'true'
    );

    const toggleChatbotServer = () => {
        
        
        localStorage.setItem(
            "openai", 
            JSON.stringify(
                !JSON.parse(localStorage.getItem("openai") || "false")
            )
        );
        
        setUseOpenAiModel( state => !state );

        !useOpenAiModel && handleUserMessage({ text: "Presentate." });
    }

    return (
        <IconButton
            className="dropdown-option"
            onClick={toggleChatbotServer}
            sx={iconBtnSx}
            disableRipple
        >
            {useOpenAiModel ? <BsToggleOn/> : <BsToggleOff/>}
            {useOpenAiModel ? "Cambiar a Chatbot" : "Cambiar a OpenAI"}
        </IconButton>
    );
}

export {
    OpenAIModelSwitcher
}