// Material
import { IconButton } from '@mui/material';
import { BsThreeDots } from 'react-icons/bs';

/**
 * Dropdown Menu Button Component
 * @returns {JSX.Element} - Dropdown Menu Button Component
 */

export const DropdownMenuBtn = ({ toggleDropdownVisibility, iconBtnSx }) => {

    return (
        <IconButton
            id="dropdownMenuButton"
            onClick={toggleDropdownVisibility}
            sx={iconBtnSx}
        >
            <BsThreeDots className='header__button-icon'/>
        </IconButton>
    );
};