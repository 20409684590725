import { useState, useEffect } from "react";

/**
 * Delayed component that renders its children after a specified delay.
 * @param {object} props - Component props
 * @param {JSX.Element} props.children - Children to be rendered
 * @param {function} props.callback - Callback function to be executed after the delay
 * @param {number} props.delay - Delay in milliseconds
 * @returns {JSX.Element} - Delayed component
 */
export const Delayed = ({ children, callback = () => {}, delay = 0 }) => {
  const [isShown, setIsShown] = useState(false);

  // Timer to show the children after the specified delay
  useEffect(() => {
    // If the component is already shown, execute the callback function
    if (isShown) {
      callback();
      // If the component is not shown, show it after the specified delay
    } else {
      const timeout = setTimeout(() => {
        setIsShown(true);
      }, delay);
      return () => clearTimeout(timeout);
    }
  }, [delay, callback, isShown]);

  return isShown ? children : null;
};
