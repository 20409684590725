import { checkSessionExpiration } from "./localStorage"

/**
 * Get and/or create local message tracker.
 * @returns {Object} - Tracker Object
*/
export function getSessionMessages() {
         
    const tracker = localStorage.getItem('tracker')

    // Tracker does not exist or session has expired. Create it.
    if(!tracker || checkSessionExpiration()) {
        localStorage.setItem('tracker', JSON.stringify([]))         
    } 

    const trackerObject = JSON.parse(localStorage.getItem('tracker'))

    for (let i = 0; i < trackerObject.length; i++) {
        // Last message in tracker
        if (i === trackerObject.length - 1) {
          break; // Mantains buttons in last message
        }
        // Delete buttons from messages
        delete trackerObject[i].buttons
      }

    return trackerObject
}   

/**
 * Save message/s to local storage.
 * @param {Array} messages - Array of messages to save.
 * @returns {void}
 */   
export async function saveMessagesToLocalStorage(messages) {
    const tracker = getSessionMessages()

    localStorage.setItem('tracker', JSON.stringify([...tracker, ...messages]))
}

export async function clearSessionMessagesFromLocalStorage() {
    localStorage.setItem('tracker', JSON.stringify([]))
}