import React from "react";
import { useRef, useContext, useEffect } from "react";

// Context
import { ChatContext } from "../Chatbot";
import { ChatUIContext } from "../ChatbotUI";

// Components
import { ChatMessage } from "../messages/ChatMessage";
import { ThinkingMessage } from "../messages/ThinkingMessage";

// Material
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

/**
 * Chat Body Component - Renders the chat messages.
 * @returns {JSX.Element} - Chat Body Component
 */
export const ChatBody = ({isMaximized}) => {
    const { chatMessages } = useContext(ChatContext);
    const { isThinking } = useContext(ChatUIContext);

    const chatScrollableDiv = useRef(null);

    const theme = useTheme();

    const scrollBarSx = {
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.scrollBar.main
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.scrollBar.thumb,
            border: `3px solid ${theme.palette.scrollBar.main}`
        },

        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.scrollBar.hover
        },       
    }

    useEffect(() => {
        const chatBodyHeight = chatScrollableDiv.current?.scrollHeight;
        chatScrollableDiv.current?.scrollTo(0, chatBodyHeight);
    }, [chatMessages]);

    return (
        <Box
            ref={chatScrollableDiv}
            id="chat-body"
            className="chat-body"
            sx={scrollBarSx}
        >
             {chatMessages.map((message, index) => (
                 <ChatMessage key={index} message={message} isMaximized={isMaximized} />
             ))}
             {isThinking && <ThinkingMessage isMaximized={isMaximized}/>}
        </Box>
    );
};
