import { createTheme } from '@mui/material/styles';

const mainTheme =  createTheme({
    palette: {
      chatContainer:{
        border:'0.0625rem solid rgb(234, 238, 243)',
        shadow:'0 9.5px 12.7px 0 rgba(0,0,0,.05)'
      },
      
      chatBody: {
        background: 'rgb(234, 238, 243)',
      },

      chatMsg: {
        background: '#ffffff',
        userText: '#7a7a7a',
        botText: "#000000",
        shadow: 'rgba(0, 0, 0, 0.07) 0rem 0.063rem 0.25rem'
      },

      chatMsgFullScreen: {
        background: 'rgb(234, 238, 243)',
      },

      visibilityBtn: {
        background: '#437eeb',
        color: '#ffffff',
        borderColor: '#ffffff',
        shadow: 'rgba(0, 0, 0, 0.3) 0px 0.25rem 0.125rem -0.125rem, rgba(0, 0, 0, 0.2) 0.125rem 0px 0.125rem -0.125rem, rgba(0, 0, 0, 0.2) -0.125rem 0px 0.125rem -0.125rem' 
      },

      dropdownMenu: {
        color: '#000000',
        colorHover: '#437eeb',
      },

      messageBtn: {
        background: '#ffffff',
        color: '#437eeb',
        border: '#437eeb',
        colorHover: '#ffffff',
        borderHover: '#ffffff',
        backgroundHover: '#437eeb' 
      },

      black:{
        main: "#000000"
      },
      
      border: {
        main: "#d6d6d6"
      },
      
      link: {
        main: "#085fff",
        hover: "#A1CAF1",
      },

      scrollBar: {
        main: "rgb(234, 238, 243)",
        thumb: "#437eeb",
        hover: "#3c6cc1"
      },

      chatHeader:{
        background: "#ffffff",
        color: '#7a7a7a',
        shadow:'0 9.5px 12.7px 0 rgba(0,0,0,.05)'
      },

      chatHeaderFullScreen: {
        background: 'rgb(234, 238, 243)'
      },

      headerIconBtn:{
        color: '#000000', 
        hover_color: "#ffffff",
        hover_background:'#437eeb', 
      },

	  onCallheaderIconBtn:{
        color: '#ce3c3c', 
        hover_color: "#ffffff",
        hover_background:'#ce3c3c',
      },

      chatFooter:{
        background: "#ffffff",
        color: 'rgb(33, 37, 41)',
        border:'rgb(234, 234, 234)',
        shadow:'0 9.5px 12.7px 0 rgba(0,0,0,.05)',
      },

      chatFooterFullScreen:{
        background: "#ffffff",
        color: '#7a7a7a',
        border:'rgb(234, 234, 234)',
        shadow:'0 9.5px 12.7px 0 rgba(0,0,0,.05)',
      },

      sendBtn:{
        background:'#ffffff',
        color:'#437eeb', 
        hover_background:'#ffffff'
      }
    },

    breakpoints: {
      values: {
        xs: 0, // Extra small devices (portrait phones, less than 600px)
        sm: 600, // Small devices (landscape phones, 600px and up)
        md: 960, // Medium devices (tablets, 960px and up)
        lg: 1280, // Large devices (desktops, 1280px and up)
        xl: 1920, // Extra large devices (large desktops, 1920px and up)
      },
    },
    
    typography: {
        fontFamily: "Poppins",
    },

  });

  export default mainTheme;