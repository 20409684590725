import React from "react";

// Material
import { useTheme } from '@mui/material/styles';
import { IconButton} from "@mui/material";
import { IoSend } from 'react-icons/io5';

/**
 * Send Button Component
 * @returns {JSX.Element} - Send Button Component
 */
export const SendBtn = () => {
    const theme = useTheme();
    return (
        <IconButton type="submit" className="footer__button"
            sx={{
                backgroundColor: theme.palette.sendBtn.background,
                color: theme.palette.sendBtn.color,
                '&:hover': {
                    backgroundColor: theme.palette.sendBtn.hover_background      
                },
            }}
            disableRipple
        >
    
        <IoSend size="1.4rem" transition= "all 0.5s ease-in-out"/>
          
        </IconButton>
                     
    );
};
