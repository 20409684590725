import { useContext } from "react";
import { ChatContext } from "../Chatbot";
import { getSessionMessages } from "../../utils/localTracker";

// Material
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FaComments, FaTimes } from 'react-icons/fa';

/**
 * VisibilityToggleBtn - Big floating button to toggle the visibility of the chatbot
 * @param {object} props - Component props
 * @param {boolean} props.chatIsVisible - Visibility state of the chatbot. Class name is changed based on this value.
 * @param {function} props.handleButtonClick - Callback function to toggle the chatbot visibility.
 * @returns {JSX.Element} - VisibilityToggleBtn Component
 */
export const VisibilityToggleBtn = ({ chatIsVisible, toggleVisibility }) => {
    
    const { handleUserMessage } = useContext(ChatContext);
    const theme = useTheme();

    const handleButtonClick = () => {
        toggleVisibility();
        
        // Send a message to the chatbot when the chatbot is opened for the first time
        if (getSessionMessages().length === 0) {
            handleUserMessage({ text: "Hola" });
        }
    };

    return (
        <IconButton
            className={chatIsVisible ? "visibility-toggle-button" : "visibility-toggle-button pulse"}
            variant='outline'
            sx={{
                position: 'fixed',
                color: theme.palette.visibilityBtn.color,
                backgroundColor: theme.palette.visibilityBtn.background,
                border: `0.0625rem solid ${theme.palette.visibilityBtn.border}`,
                boxShadow: theme.palette.visibilityBtn.shadow
                
            }}
            onClick={handleButtonClick} 
            disableRipple
        >
            {chatIsVisible ? <FaTimes size="1.875rem"/> : <FaComments size="1.875rem"/>}

    </IconButton>
 
    );
};
