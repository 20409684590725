import React from "react";
import { Animated } from "../utility/AnimatedComponent";

// Assets
import threeDots from "../../assets/img/three-dots.gif";

// Material
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const isFullScreen = process.env.REACT_APP_CHATBOT_FULL_SCREEN === 'true';

export const ThinkingMessage = ({isMaximized}) => {
    const theme = useTheme();

    return (
        <Animated>
            <Box
                className="chat-bubble"
                sx={{
                    
                    [theme.breakpoints.up('xs')]: {
                        marginLeft: isFullScreen && isMaximized ? '1rem' : undefined,
                        marginRight: isFullScreen && isMaximized ? '1rem' : undefined,
                    },
                    [theme.breakpoints.up('sm')]: {
                        marginLeft: isFullScreen && isMaximized ? '4rem' : undefined,
                        marginRight: isFullScreen && isMaximized ? '4rem' : undefined,  
                    },
                    [theme.breakpoints.up('md')]: {
                        marginLeft: isFullScreen && isMaximized ? '15rem' : undefined,
                        marginRight: isFullScreen && isMaximized ? '15rem' : undefined,
                    },
                    [theme.breakpoints.up('xl')]: {
                        marginLeft: isFullScreen && isMaximized ? '35rem' : undefined,
                        marginRight: isFullScreen && isMaximized ? '35rem' : undefined,
                    },
                    boxShadow: isFullScreen && isMaximized ? undefined : theme.palette.chatMsg.shadow,
                    background:  isFullScreen && isMaximized ? theme.palette.chatMsgFullScreen.background : theme.palette.chatMsg.background,                    
                }}
            >
                <img className='thinking-message' src={threeDots} alt="thinking" />
            </Box>
        </Animated>
    );
};
