// Styles
import "./App.css";

// Components
import { ChatbotUI } from "./components/ChatbotUI";

// Theme
import { ThemeProvider } from '@mui/material/styles';
import { WindowProvider } from './contexts/WindowContext';
import mainTheme from './themes';
import { Container } from "@mui/material";
import { CallProvider } from "./contexts/CallContext";

/**
 * Main App/Demo Component
 * @returns {JSX.Element} - App Component
 */
function App() {
    return (
        <WindowProvider>
            <CallProvider>
                <ThemeProvider theme={mainTheme}>
                    {/* <Container className="app"> */}
                        {/* Chatbot */}
                        <ChatbotUI title={process.env.REACT_APP_CHATBOT_TITLE} />

                    {/* </Container> */}
                </ThemeProvider>
            </CallProvider>
        </WindowProvider>
    );
}

export default App;
