import React, { useContext, useEffect } from "react";

// Hooks
import { useForm } from "../../hooks/useForm";

// Context
import { ChatContext } from "../Chatbot";
import { CallContext } from "../../contexts/CallContext";

// Components
import { SendBtn } from "../buttons/SendBtn";

// Material
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, TextareaAutosize, Input } from "@mui/material";

// Initial states
const initialState = {
    inputMsg: "",
};
const formValidations = {
    inputMsg: [(value) => value.length > 0, "El mensaje no puede estar vacío"],
};

/**
 * form component for chat input
 * @returns {JSX.Element} - Chat input component
 */
export const ChatInput = () => {
    const { inputMsg, onInputChange, isFormValid, inputMsgValid } = useForm(
        initialState,
        formValidations
    );
    const theme = useTheme();
    const { handleUserMessage } = useContext(ChatContext);
    const { isOnCall, speechToText } = useContext(CallContext);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Input validation
        if (!isFormValid) {
            alert(inputMsgValid);
            return;
        }

        // Send user message to the chatbot
        handleUserMessage({
            text: inputMsg,
        });

        // Clear input
        onInputChange({ target: { name: "inputMsg", value: "" } });
    };

    const handleTextareaKeyDown = (e) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            handleSubmit(e);
        }
    };
    useEffect(() => {
        if (isOnCall && speechToText) {
            handleUserMessage({
                text: speechToText,
            });
        }
    }, [speechToText, isOnCall]);

    return (
        <FormControl className="input-form" component="form" onSubmit={handleSubmit} method="post">
                <Box className={"input-container"} hidden={isOnCall}>
                        <Input
                            id="user-input"
                            className={"form-control input-msg"}
                            placeholder="Escriba su pregunta"
                            value={inputMsg}
                            name="inputMsg"
                            onChange={onInputChange}
                            onKeyDown={handleTextareaKeyDown}
                            multiline
                            disableUnderline
                        />
                        <SendBtn />
                </Box>
        </FormControl>
        
    );
   
};
