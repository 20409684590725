import { React } from "react";

// Utility Components
import { Animated } from "../utility/AnimatedComponent";
import { Delayed } from "../utility/DelayedComponent";
import { ScrollComponent } from "../utility/ScrollComponent";

// Material
import { Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';

/**
 * Message Button Component. Use to give the user options to choose from
 * @param {Object} props - Component props
 * @param {String} props.text - Text content of the button
 * @param {String} props.payload - Payload content of the button
 * @param {Number} props.delay - Delay in ms to display the button
 * @param {Function} props.onMsgBtnClick - Function to handle the click event
 * @returns {JSX.Element} - Message Button Component
 */
export const MessageBtn = ({ text, payload, delay, onMsgBtnClick }) => {
    const theme = useTheme();
    const messageBtnSx = {
        backgroundColor: theme.palette.messageBtn.background,
        border: `0.1rem solid ${theme.palette.messageBtn.border}`,
        color: theme.palette.messageBtn.color,
        zIndex: -20,
        
        '&:hover': {
            backgroundColor: theme.palette.messageBtn.backgroundHover,
            borderColor: theme.palette.messageBtn.borderHover,
            color: theme.palette.messageBtn.colorHover
        }
    }
    
    const handleClick = () => {
        onMsgBtnClick(text, payload);
    };

    return (
        <Delayed delay={delay}>
            <ScrollComponent>
                <Animated className="button-message-container">
                        <Button
                            className="button-message"
                            onClick={handleClick}
                            sx={messageBtnSx}
                            disableRipple
                        >
                            {text}
                        </Button>
                </Animated>
            </ScrollComponent>
        </Delayed>
    );
};
