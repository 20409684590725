import { useContext, useState, useEffect } from "react";

// Framer Motion
import { motion } from "framer-motion";

// Contexts
import { ChatUIContext } from "../ChatbotUI";

// Components
import { ChatHeader } from "./ChatHeader";
import { ChatBody } from "./ChatBody";
import { ChatFooter } from "./ChatFooter";
import { ChatFooterFullScreen } from "./ChatFooterFullScreen";
import { ChatHeaderFullScreen } from "./ChatHeaderFullScreen";

// Material
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery} from "@mui/material";

/**
 * Main Chat Container Component - Provides the context for the chatbot UI changes.
 * @param {object} props - Component props
 * @param {String} props.title - Title of the chat window
 * @returns {JSX.Element} - ChatContainer Component
 */
const ChatContainer = () => {
    const { chatIsVisible } = useContext(ChatUIContext);
    const [isMaximized, setIsMaximized] = useState(false);
    
    const handleToggleMaximize = () => {
      setIsMaximized(!isMaximized);
    };

    const theme = useTheme();

    // Animation variants
    const variants = {
        open: { opacity: 1, x: "0" },
        closed: { opacity: 0, x: "130%" },
    };

    const chatContainerBackgroundSx = {
        backgroundColor: theme.palette.chatBody.background,
    }

    const chatContainerBorderSx = {
        border: theme.palette.chatContainer.border
    }

    const chatContainerSx = { ...chatContainerBackgroundSx, ...chatContainerBorderSx }
    const isFullScreen = process.env.REACT_APP_CHATBOT_FULL_SCREEN === 'true';
    const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    
    useEffect(() => {
      if (chatIsVisible && isFullScreen && isXsScreen) {
        setIsMaximized(true);
      } else {
        setIsMaximized(false);
      }
    }, [chatIsVisible, isFullScreen, isXsScreen]);
    return (
        
        <Box
            component={motion.div}
            animate={chatIsVisible ? "open" : "closed"}
            variants={variants}
            className={`chat-container ${chatIsVisible}`}
            sx={{
                boxShadow: theme.shadows[4],
                ...chatIsVisible ? chatContainerSx : chatContainerBackgroundSx , 
                borderRadius: isFullScreen && isMaximized ? 'unset' : undefined,
                left: isFullScreen && isMaximized ? '0px' : undefined,
                [theme.breakpoints.up('xs')]: {
                  right: '1.3rem',
                  bottom: isFullScreen && isMaximized ? '.1rem' :'1.3rem',
                  height: isFullScreen && isMaximized ? '100%': '95%',
                  width: isFullScreen && isMaximized ? '100%': '90%',
                  },
                [theme.breakpoints.up('sm')]: {
                  right: '2.3rem',
                  bottom: isFullScreen && isMaximized ? '.1rem' : isMaximized ? '2.3rem' : '1.3rem',
                  maxHeight: '560px',
                  minHeight:'55%',
                  width:'410px',
                },  
                [theme.breakpoints.up('lg')]: {
                  right: '2.5rem',
                  bottom: isFullScreen && isMaximized ? '.1rem' :'2.5rem',
                  maxHeight: '560px',
                  minHeight:'55%',
                  width:'410px',
                },
                [theme.breakpoints.up('xl')]: {
                  maxHeight: '560px',
                  minHeight:'55%',
                  width:'410px',
                },
                maxHeight: isFullScreen  && isMaximized ? `${100}% !important` : (!isFullScreen && isMaximized) ? `${window.innerHeight-80}px !important`: `undefined`,  
                width: isFullScreen  && isMaximized ? `${100}% !important` : (!isFullScreen  && isMaximized) ? `${window.innerWidth-80}px !important`: `undefined`,  
              }}
        >
            {isFullScreen  && isMaximized ?  <ChatHeaderFullScreen handleToggleMaximize={handleToggleMaximize} isMaximized={isMaximized}/> : <ChatHeader handleToggleMaximize={handleToggleMaximize} isMaximized={isMaximized}/>}
            

            <ChatBody isMaximized={isMaximized}/>
            
            {isFullScreen  && isMaximized ? <ChatFooterFullScreen/> : <ChatFooter/>}
        </Box>
    );
};
export default ChatContainer;
