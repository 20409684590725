import { createContext } from "react";

// Hooks
import { useChatUI } from "../hooks/useChatUI";

// Components
import { Chatbot } from "./Chatbot";

// set the defaults for context
export const ChatUIContext = createContext({
    title: "Chatbot",

    // Chat visibility state
    chatIsVisible: false,
    toggleVisibility: () => {},

    // Chat thinking/messages display state
    isThinking: false,
    toggleThinking: () => {},

    // Messages display timers
    messageDisplayTimers: [],
    setMessageDisplayTimers: () => {},

    // Chat has delayed messages
    chatHasDelayMessages: true,
    toggleMessageDelay: () => {},
});

/**
 * Chatbot UI Component - Provides the context for the chatbot UI state nd changes.
 * @param {String} title - Title of the chat window
 * @returns {JSX.Element} - ChatbotUI Component
 */
export const ChatbotUI = ({ title }) => {
    const { 
        chatIsVisible, 
        toggleVisibility, 
        isThinking, 
        toggleThinking,
        messageDisplayTimers,
        setMessageDisplayTimers,
        chatHasDelayMessages,
        toggleMessageDelay,
    } = useChatUI();

    const contextValue = {
        title,
        // Chat visibility state
        chatIsVisible,
        toggleVisibility,
        // Chat thinking state
        isThinking,
        toggleThinking,
        // Messages display timers
        messageDisplayTimers,
        setMessageDisplayTimers,
        // Chat has delayed messages
        chatHasDelayMessages,
        toggleMessageDelay,
    };

    return (
            <ChatUIContext.Provider value={contextValue}>
                    <Chatbot />
            </ChatUIContext.Provider>
    );
};
