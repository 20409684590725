import React from "react";
import { Animated } from "../utility/AnimatedComponent";

/**
 * Image message component
 * @param {Object} props - Component props
 * @param {String} props.src - Image source
 * @returns {JSX.Element} - Image Message Component
 */
export const ImageMessage = ({ src }) => {
    return (
        <Animated>
            <img src={src} alt="bot-graphic" width="300" height="300"></img>
        </Animated>
    );
};
