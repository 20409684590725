import { useContext, useState, useCallback, useEffect } from "react";

// Contexts
import { ChatUIContext } from "../components/ChatbotUI";

// Utils
import { sendUserMessage } from "../utils/sendUserMessage";
import { createBotMessages, createUserMessage } from "../utils/messageBuilders";
import { getMessageDelay } from "../utils/time";
import { clearSessionMessagesFromLocalStorage, getSessionMessages, saveMessagesToLocalStorage } from "../utils/localTracker"

/**
 * @typedef {Object} chat
 * @property {Array} chatMessages - Array of chat messages
 * @property {()=>void} handleUserMessage - Function to handle user messages
 * @property {()=>void} emptyChat - Function to empty the chat
 */
/**
 * Hook to handle the chat messages and the chatbot logic
 * @returns {chat} - Chat messages and handle user message function
 */
export const useChat = () => {
    const [chatMessages, setChatMessages] = useState([]);
    const {
        isThinking,
        toggleThinking,
        messageDisplayTimers,
        setMessageDisplayTimers,
        chatHasDelayMessages,
    } = useContext(ChatUIContext);

    // Use effect to get messages from local storage on mount for the first time
    useEffect(() => {
        // Get messages from local storage
        const messages = getSessionMessages();

        // Set messages
        setChatMessages(messages);
    }, []);

    /**
     * Algorithm to set delayed messages in the chat
     * @param {Array} newMessages - Array of new messages
     * @returns {Array} - Array of message display timers
     */
    const setDelayedMessages = useCallback((newMessages) => {
        // Accumulated delay for each sequential message
        let accumulatedDelay = 0;
        let messageDelay = 0;
        const newMessageTimers = [];

        for (let index = 0; index < newMessages.length; index++) {
            const botMessage = newMessages[index];

            const messageTimer = setTimeout(() => {
                setChatMessages((chat) => [...chat, botMessage]);

                // Last message - Stop thinking
                if (index === newMessages.length - 1) {
                    toggleThinking();
                }
            }, messageDelay + accumulatedDelay);

            // Update message timers
            newMessageTimers.push(messageTimer);
            // Update accumulated delay
            accumulatedDelay += messageDelay;
            // Calculate delay for next message
            messageDelay = getMessageDelay(botMessage.text, accumulatedDelay);
        }

        return newMessageTimers;
    }, [toggleThinking]);

    const setMessages = useCallback((newMessages) => {
        // Set messages
        setChatMessages((chat) => [...chat, ...newMessages]);
        // Stop thinking
        toggleThinking();
    }, [toggleThinking]);

    /**
     * Stop undisplayed messages
     * @param {Array} messageDisplayTimers - Array of message display timers
     * @param {Boolean} isThinking - 
     */
    const stopUndisplayedMessages = useCallback(
        (messageDisplayTimers, isThinking) => {
            if (isThinking) {
                toggleThinking();
                messageDisplayTimers.forEach((timer) => clearTimeout(timer));
            }
        },
        [toggleThinking]
    );

    /**
     * Function to empty the chat messages
     * @returns {void}
     */
    const emptyChat = useCallback(() => {
        // Stop undisplayed messages
        stopUndisplayedMessages(messageDisplayTimers, isThinking);
        // Empty chat messages on state
        setChatMessages([]);
        // Empty chat messages on local storage
        clearSessionMessagesFromLocalStorage();
    }, [messageDisplayTimers, isThinking, stopUndisplayedMessages]);
    
    /**
     * Main function to handle user messages and send them to Rasa server
     * @param {Object} message - Message object
     * @param {String} message.text - Message text
     * @param {String} message.payload - Message payload
     * @returns {Promise} - Promise to resolve the bot response
     */
    const handleUserMessage = async ({ text, payload }, isFirstMessage) => {
        // Stop undisplayed messages
        stopUndisplayedMessages(messageDisplayTimers, isThinking);
    
        // Update the chat messages with user message
        const userMessage = isFirstMessage ? createUserMessage('') : createUserMessage(text);

        setChatMessages((chat) => [...chat, userMessage]);

        // Start thinking/requesting bot response
        toggleThinking();

        // Send user message to Rasa
        const userPayload = payload || text;
        const serverBotMessages = await sendUserMessage(userPayload);

        // Update the chat messages with bot messages
        const botMessages = createBotMessages(serverBotMessages);

        // If there are no delay messages, set them immediately
        if (chatHasDelayMessages) {
            // Set delayed messages
            const newMessageTimers = setDelayedMessages(botMessages);
    
            // Update message display timers
            setMessageDisplayTimers(newMessageTimers);
    
        // Else set inmidiate messages
        } else {        
            setMessages(botMessages);
        }

        // Save messages in local storage for persistence between page reloads.
        saveMessagesToLocalStorage([userMessage, ...botMessages]);

        return true;
    };

    return {
        // Properties
        chatMessages,
        // Methods
        handleUserMessage,
        emptyChat,
    };
};
