import { useContext } from "react";
import { ChatUIContext } from "../ChatbotUI";

// Material
import { IconButton } from '@mui/material';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
 
export const DelaySwitcher = ({ iconBtnSx }) => {
    const { chatHasDelayMessages, toggleMessageDelay  } = useContext(ChatUIContext);

    return (
        <IconButton
            className="dropdown-option"
            onClick={toggleMessageDelay}
            sx={iconBtnSx}
            disableRipple
        >
            {chatHasDelayMessages ? <BsToggleOn/> : <BsToggleOff/>}
            Cambiar retraso de mensajes
        </IconButton>
    );
};
